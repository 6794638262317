import EnterCodeAccess from "../pages/Login/accessKey/EnterCodeAccess";
import UploadDocumentsReq from "../pages/Login/accessKey/UploadDocumentsReq";
import UploadSuccess from "../pages/Login/accessKey/UploadSuccess";
import Registration from "../pages/Registration/Registration";
import PersonalInformation from "../pages/Registration/PersonalInformation";
import Stripe from "../pages/Registration/Stripe";
import Thankyou from "../pages/Registration/Thankyou";
import UploadDocuments from "../pages/Registration/UploadDocuments";
import FacialRecognition from "../pages/Registration/FacialRecognition";
import Interview from "../pages/Registration/Interview";
import RegistrationSuccess from "../pages/Registration/RegistrationSuccess";
import GuardianInformation from "../pages/Registration/minorApplicant/GuardianInformation";
import FacialRecognitionApproved from "../pages/Registration/minorApplicant/FacialRecognitionApproved";
import ScanFace from "../pages/Registration/FaceScanner/ScanFace";
import EmailVerified from "../pages/Registration/EmailVerified";
import EmailVerifiedFailed from "../pages/Registration/EmailVerifiedFailed";
import PageNotFound from "../../src/PageNotFound";
import RegistrationDone from "../pages/Registration/RegistrationDone";
import InterviewProcess from "../pages/Registration/InterviewProcess";
import GoogleAuthCallback from "../pages/Registration/GoogleAuthCallback";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import DashboardNavbar from "../pages/Dashboard/components/DashboardNavbar";
import NavigationBar from "../pages/Basic/components/NavigationBar";
import Home from "../pages/Basic/Home/Home";
import WhyRegister from "../pages/Basic/whyRegister/WhyRegister";
import BenefitPageMain from "../pages/Basic/benefit/BenefitPageMain";
import Footer from "../pages/Basic/components/Footer";
import RequirementsMain from "../pages/Basic/requirements/RequirementsMain";
import AboutUsMain from "../pages/Basic/aboutUs/AboutUsMain";
import AboutProfile from "../pages/Basic/aboutProfile/AboutProfile";
import Faq from "../pages/Basic/faq/Faq";
import CategoryFaqs from "../pages/Basic/faq/components/CategoryFaqs";
import ContactMain from "../pages/Basic/contact/ContactMain";
import PrivacyMain from "../pages/Basic/privacy/PrivacyMain";
import AntibriberyMain from "../pages/Basic/antibriberyPolicy/AntibriberyMain";
import TermsAndConditionMain from "../pages/Basic/termsAndCondition/TermsAndConditionMain";
import LoginMain from "../pages/Login/LoginMain";
import ForgotPassMain from "../pages/Login/forgotPassword/ForgotPassMain";
import PasswordSuccess from "../pages/Login/forgotPassword/PassUpdateSuccess";
import QRScan from "../pages/Login/2fa/QRScan";
import OTPVerify from "../pages/Login/2fa/OTPVerify";
import RecoveryCodes from "../pages/Login/2fa/RecoveryCodes";
import TwoFAFromApp from "../pages/Login/2fa/TwoFAFromApp";
import EnterRecoveryCodes from "../pages/Login/2fa/EnterRecoveryCodes";
import PendingApproval from "../pages/Registration/PendingApproval";

// Protected Route component for authentication
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userHasToken = Boolean(localStorage.getItem("token"));
  return userHasToken ? <Component {...rest} /> : <Navigate to="/login" />;
};

// Define static login navigation paths
const loginPaths = [
  "/login",
  "/register",
  "/forgot-password",
  "/qr-scan",
  "/otp-verify",
  "/recovery-codes",
  "/2fa-authApp",
  "/enter-recovery-codes",
  "/password-success",
  "/email-verified",
  "/email-verification/failed",
  "/registration-success",
  "/registration-done",
  "/pending-approval",
];

// Define static registration navigation paths
const registrationNavPaths = [
  "/payment",
  "/thankyou",
  "/enter-access-key",
  "/upload-documentsreq",
  "/upload-success",
  "/upload-documents",
  "/face-recognition",
  "/interview-process",
  "/interview",
  "/guardian-information",
  "/personal-information",
  "/face-recognition-minor",
];

// Define dynamic registration navigation paths
const dynamicRegistrationNavPaths = ["/face-scan/"];

function NotLoggedInNavigations() {
  // State variables
  const [pageToLogin, setPageToLogin] = useState(false);
  const [showRegistrationNav, setShowRegistrationNav] = useState(false);
  const location = useLocation();
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  // Combined useEffect to handle both location change and fetching team members
  useEffect(() => {
    // Check if the current path is a login page
    const isLoginPage = loginPaths.some((path) =>
      location.pathname.startsWith(path)
    );
    // Check if the current path is a registration navigation page
    const isRegistrationNavPage = [
      ...registrationNavPaths,
      ...dynamicRegistrationNavPaths,
    ].some((path) => location.pathname.startsWith(path));

    // Update state based on the current path
    setPageToLogin(isLoginPage || isRegistrationNavPage);
    setShowRegistrationNav(isRegistrationNavPage);

    // Fetch team members if not already loaded
    if (teamMembers.length === 0 && !loadingMembers) {
      fetchMembers();
    }
  }, [location.pathname, loadingMembers, teamMembers.length]);

  // Function to fetch team members
  const fetchMembers = async () => {
    try {
      setLoadingMembers(true);
      // Fetch data from two different endpoints
      const [response1, response2] = await Promise.all([
        axios.get(`${process.env.REACT_APP_CEO_API_URL}/api/country-manager`),
        axios.get(
          `${process.env.REACT_APP_CEO_API_URL}/api/senior-country-manager`
        ),
      ]);

      // Format the fetched data
      const formatMembers = (data, position) =>
        data.map((item) => ({
          ...item,
          fullname: `${item.first_name} ${item.middle_name} ${item.last_name}`,
          position,
        }));

      setTeamMembers([
        ...formatMembers(response1.data, "Country Manager"),
        ...formatMembers(response2.data, "Sr. Country Manager"),
      ]);
    } catch (error) {
      console.error("Failed to fetch team members: ", error);
    } finally {
      setLoadingMembers(false);
    }
  };

  // Define protected routes with their respective components
  const protectedRoutes = [
    { path: "/enter-access-key", component: EnterCodeAccess },
    { path: "/upload-documentsreq", component: UploadDocumentsReq },
    { path: "/upload-success", component: UploadSuccess },
    { path: "/personal-information", component: PersonalInformation },
    { path: "/payment", component: Stripe },
    { path: "/thankyou", component: Thankyou },
    { path: "/upload-documents", component: UploadDocuments },
    { path: "/face-recognition", component: FacialRecognition },
    { path: "/face-scan/:key", component: ScanFace },
    { path: "/interview-process", component: InterviewProcess },
    { path: "/interview", component: Interview },
    { path: "/registration-done", component: RegistrationDone },
    { path: "/guardian-information", component: GuardianInformation },
    { path: "/face-recognition-minor", component: FacialRecognitionApproved },
  ];

  return (
    <>
      {/* Navigation bar */}
      {!pageToLogin && <NavigationBar />}
      {showRegistrationNav && <DashboardNavbar />}

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/why-register" element={<WhyRegister />} />
        <Route path="/benefits" element={<BenefitPageMain />} />
        <Route path="/requirements" element={<RequirementsMain />} />
        <Route
          path="/about-us"
          element={
            <AboutUsMain teamMembers={teamMembers} loading={loadingMembers} />
          }
        />
        <Route path="/about-profile" element={<AboutProfile />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/faq/category" element={<CategoryFaqs />} />
        <Route path="/contact" element={<ContactMain />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionMain />}
        />
        <Route path="/privacy" element={<PrivacyMain />} />
        <Route path="/anti-bribery-policy" element={<AntibriberyMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/forgot-password" element={<ForgotPassMain />} />
        <Route path="/password-success" element={<PasswordSuccess />} />
        <Route path="/qr-scan" element={<QRScan />} />
        <Route path="/otp-verify" element={<OTPVerify />} />
        <Route path="/recovery-codes" element={<RecoveryCodes />} />
        <Route path="/2fa-authApp" element={<TwoFAFromApp />} />
        <Route path="/enter-recovery-codes" element={<EnterRecoveryCodes />} />
        {protectedRoutes.map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={<ProtectedRoute component={Component} />}
          />
        ))}
        <Route path="/register" element={<Registration />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/pending-approval" element={<PendingApproval />} />
        <Route
          path="/email-verification/failed"
          element={<EmailVerifiedFailed />}
        />
        <Route path="/auth/callback" element={<GoogleAuthCallback />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

      {/* Footer */}
      {!pageToLogin && <Footer />}
    </>
  );
}

export default NotLoggedInNavigations;
