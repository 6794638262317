import React from "react";

function OurVision() {
  return (
    <div className="w-full max-w-main max-md:mt-10 max-md:max-w-full max-md:px-0 m-auto px-10">
      <div className="flex items-start gap-5 max-md:flex-col max-md:gap-0 bg-devRegDarkBlue px-20 max-md:px-5 py-16 rounded-2xl max-md:rounded-none">
        <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
          <img
            alt="img"
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3d7001eafcac3428045d6269ea533e22f2aec7428c415671caa889dc1bc32991?apiKey=3ae96302e69649f5be368f89230b0596&"
            className=" w-full aspect-[0.85] max-md:mt-0 max-sm:w-[210px] max-sm:aspect-[0.90] m-auto max-md:w-[280px] max-md:aspect-[0.87] "
          />
        </div>
        <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col justify-center items-start self-stretch px-5 max-md:px-0 max-sm:py-4 max-md:mt-0 my-auto text-2xl tracking-tight max-sm:mt-0 max-md:max-w-full max-md:text-center">
            <div className="self-stretch text-base tracking-tighter leading-6 text-neutral-600 max-md:max-w-full ">
              <span className="text-3xl font-bold tracking-tighter leading-10 text-white max-md:text-center">
                Our Vision for Secure Collaboration in Software Development
              </span>
              <br />
              <br />
              <span className="leading-7 text-white">
                The world of software development thrives on innovation,
                creativity, and collaboration—but it is also vulnerable to
                exploitation. Scams targeting developers and their clients have
                created an environment of distrust, stifling growth and
                opportunities. I founded Devreg.org to eliminate this
                uncertainty and empower developers and clients alike to work
                together with confidence. By implementing rigorous identity
                verification, we ensure that every professional on our platform
                is who they claim to be—reducing risks, protecting reputations,
                and fostering trust. This is more than a platform; it’s a
                movement to reshape the way the software industry connects,
                works, and thrives. Devreg.org is my answer to the challenges
                that plague the developer community—a beacon of integrity and a
                trusted ally for developers worldwide.
              </span>
              <br />
              <br />
              <span className="font-bold leading-7 text-white">
                Together, we can build a future where developers focus on what
                they do best—creating, innovating, and solving the world’s
                problems—without the shadow of fraud.
              </span>
            </div>
            <div className="mt-7 text-[24px] font-bold text-devRegGreen leading-[120%] max-md:w-full max-md:text-center">
              Jeffrey Lawson
            </div>
            <div className="text-[20px] mt-2 font-semibold text-white leading-[100%] max-md:w-full max-md:text-center">
              Managing Director of Devreg.org
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVision;
