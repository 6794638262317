import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInNavigations from "./routes/NotLoggedInNavigations";
import LoggedIn from "./routes/LoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "./actions";
import axios from "axios";

function App() {
  const isLoggedIn = useSelector((state) => state.value.isLoggedIn);
  const isLoading = useSelector((state) => state.value.isLoading);
  const dispatch = useDispatch();

  const [showComingSoonModal, setShowComingSoonModal] = useState(true);
  const [showExtendSessionModal, setShowExtendSessionModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10); // 10 mins for the warning modal

  // Check the user's login status in localStorage
  useEffect(() => {
    const checkLocalStorage = async () => {
      const isLoggedInLocal = JSON.parse(localStorage.getItem("isLoggedIn"));
      if (isLoggedInLocal) {
        await dispatch(setIsLoggedIn(true));
      } else {
        await dispatch(setIsLoggedIn(false));
      }

      // Redirect to the dashboard if the user is logged in
      if (isLoggedInLocal && window.location.pathname === "/") {
        window.location.href = "/dashboard/edit-profile?tab=overview";
      }
    };

    checkLocalStorage();

    // Prevent scrolling when loading
    if (isLoading || showComingSoonModal) {
      document.body.classList.add('overflow-hidden');
      return () => document.body.classList.remove('overflow-hidden');
    }

    // If the user is logged in, start the session timer
    if (isLoggedIn) {
      startSessionTimer();
    }
  }, [dispatch, isLoggedIn, isLoading, showComingSoonModal]);

  // Geolocation API to fetch user's address
  useEffect(() => {
    const LOCATIONIQ_API_KEY = "pk.4cd67ac67c0cfee819d0f453ae533eae";

    // Get user's coordinates
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getLocationData(latitude, longitude);
      },
      (err) => {
        console.error("Failed to retrieve location.", err);
      }
    );

    const getLocationData = async (lat, lon) => {
      try {
        const response = await axios.get(
          `https://us1.locationiq.com/v1/reverse.php?key=${LOCATIONIQ_API_KEY}&lat=${lat}&lon=${lon}&format=json`
        );

        console.log(response.data);
        localStorage.setItem("userAddress", JSON.stringify(response.data.address));
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    };
  }, []);

  // Start the session timer
  const startSessionTimer = () => {
    const sessionDuration = 60 * 60 * 1000; // 1 hour in milliseconds
    const warningTime = 50 * 60 * 1000; // 50 minutes before expiry
    const showModalTime = 10 * 60 * 1000; // Show modal only when there are 10 minutes remaining

    // Store session expiry time in localStorage
    const expiryTime = Date.now() + sessionDuration;
    localStorage.setItem('sessionExpiryTime', expiryTime);

    // Check session every minute
    const interval = setInterval(() => {
      const remainingTime = expiryTime - Date.now();

      // Show the modal only if there are 10 minutes remaining
      if (remainingTime <= showModalTime && remainingTime > showModalTime - 60000) { // 10 minutes left
        setShowExtendSessionModal(true);
        setTimeRemaining(Math.ceil(remainingTime / 60000)); // time remaining in minutes
      }

      if (remainingTime <= 0) {
        // If session expired, log the user out
        clearInterval(interval);
        handleLogout();
      }
    }, 60000); // Check every minute
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("sessionExpiryTime");
    dispatch(setIsLoggedIn(false));
    window.location.href = "/login";
  };

  // Handle session extension
  const handleStayLoggedIn = () => {
    // Extend the session for another hour
    const newExpiryTime = Date.now() + 60 * 60 * 1000; // 1 hour
    localStorage.setItem('sessionExpiryTime', newExpiryTime);
    setShowExtendSessionModal(false);
    startSessionTimer(); // Restart the session timer
  };

  // Handle session expiry (if the user declines to extend session)
  const handleSessionExpiry = () => {
    setShowExtendSessionModal(false);
    handleLogout();
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex flex-col items-center justify-center z-9999999 bg-white/75">
          <div className="relative flex justify-center items-center">
            <div className="absolute animate-spin rounded-full h-24 w-24 max-md:w-14 max-md:h-14 max-sm:w-12 max-sm:h-12 border-t-4 max-sm:border-t-2 border-b-4 max-sm:border-b-2 border-devRegBlue"></div>
            <img src="/lock.svg" className="rounded-full h-14 w-14 max-md:w-10 max-md:h-10 max-sm:w-7 max-sm:h-7" alt="Loading" />
          </div>
          <p className="mt-9 max-sm:mt-4 text-lg max-sm:text-sm font-semibold text-devRegBlue animate-pulse">Loading...</p>
        </div>
      )}

      {/* Coming Soon Modal */}
      {showComingSoonModal && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-md z-50">
          <div className="flex flex-col max-w-[695px]">
            <div className="flex gap-4 p-6 bg-white rounded-2xl shadow-lg border border border-solid min-h-[451px] max-md:px-5">
              <div className="flex flex-wrap flex-1 shrink gap-8 p-6 basis-0 min-w-[240px] size-full max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet="/ComingSoon.png"
                  className="object-contain flex-1 shrink w-full aspect-[0.8] basis-0 min-w-[240px]"
                />
                <div className="flex flex-col flex-1 shrink my-auto basis-0 min-w-[240px]">
                  <img
                    loading="lazy"
                    src="loginLogo.png"
                    className="object-contain max-w-full aspect-[3.66] w-[150px]"
                  />
                  <div className="mt-6 text-3xl font-semibold text-neutral-800">
                    Registrations Are Currently Closed
                  </div>
                  <div className="mt-6 text-base leading-6 text-gray-600">
                    Registration is currently unavailable as our website is under
                    development. We are working on exciting updates and enhancements
                    to improve your experience. Please check back soon for more
                    information!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Show session expiry modal if necessary */}
      {showExtendSessionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="session-modal bg-white rounded-lg p-6 max-w-sm w-full shadow-lg">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Session Expiry Warning</h2>
            <p className="text-gray-600 mb-4">Your session will expire in {timeRemaining} minutes.</p>
            <p className="text-gray-600 mb-6">Do you want to stay logged in?</p>
            <div className="button-container flex justify-end mt-4 gap-1">
              <button
                onClick={handleSessionExpiry}
                style={{
                  padding: '0.5rem 1.5rem',
                  color: '#1C3775',
                  backgroundColor: 'transparent',
                  border: '1px solid #1C3775',
                  cursor: 'pointer',
                  transition: 'background-color 0.2s ease-in-out'
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#1C3775';
                  e.target.style.color = 'white';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent'
                  e.target.style.color = '#1C3775';
                }}
              >
                No
              </button>
              <button
                onClick={handleStayLoggedIn}
                style={{
                  padding: '0.5rem 1.5rem',
                  backgroundColor: '#1C3775',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#0043d3';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#1C3775'
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Routing */}
      <Router>
        {isLoggedIn ? <LoggedIn /> : <NotLoggedInNavigations />}
      </Router>
    </>
  );
}

export default App;
