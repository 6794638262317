import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoggedIn, setIsLoading } from "../../actions";
import { login } from "../../api/apiCall";
// import { useAuth } from "../../contexts/authContext";
const useLogin = () => {
  const dispatch = useDispatch();
  //   const mainImg =
  //     "https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/99c9dc240f95f80cf1424e3bb59bd07f47a8e44bc410ebb22d04097c5542b560?apiKey=3ae96302e69649f5be368f89230b0596&";
  const logoImg = "";
  // "https://cdn.builder.io/api/v1/image/assets/TEMP/0fa4a55e76d2b3d29c123fbc8cb288186f69c33a12fe61eff618d75a8254a6c4?apiKey=3ae96302e69649f5be368f89230b0596&";
  //   const { login } = useAuth();

  const isLoggedIn = useSelector((state) => state.value.isLoggedIn);

  const navigate = useNavigate();
  const [loginPass, setLoginPass] = useState("");
  const [fullName, setFullName] = useState("");
  const [toggleEye, setToggleEye] = useState(false);
  const [error, setError] = useState("");
  // const [storeToken, setStoreToken] = useState(null);
  const [allSet, setAllSet] = useState(false);

  const handleToggleEye = () => {
    setToggleEye(!toggleEye);
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSignUp = () => {
    navigate("/register");
  };

  const loginUser = async () => {
    dispatch(setIsLoading(true));
    try {
      const sendData = {
        email: fullName,
        password: loginPass,
      };

      const response = await login(sendData);
      console.log(response);

      if (response.status === 200) {
        const {
          token,
          user,
          two_factor,
          features,
          languages,
          work_histories,
          portfolios,
          skills,
          educations,
          awards,
        } = response.data;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("features", JSON.stringify(features));
        localStorage.setItem("languages", JSON.stringify(languages));
        localStorage.setItem("work_histories", JSON.stringify(work_histories));
        localStorage.setItem("portfolios", JSON.stringify(portfolios));
        localStorage.setItem("skills", JSON.stringify(skills));
        localStorage.setItem("educations", JSON.stringify(educations));
        localStorage.setItem("awards", JSON.stringify(awards));
        localStorage.setItem("token", token);
        console.log("User:", user);
        console.log("Token:", token);
        // navigate("/qr-scan");

        const expiryTime = Date.now() + 60 * 60 * 1000; // 1 hour
        localStorage.setItem("sessionExpiryTime", expiryTime);

        // Determine the next route based on user status
        // let nextRoute = "/dashboard/forum";
        let nextRoute = "/2fa-authApp";

        if (user.check_registration_status?.is_profile_info_done === 0) {
          nextRoute = "/personal-information";
        } else if (user.check_registration_status?.is_paid === 0) {
          nextRoute = "/payment";
        } else if (user.check_registration_status?.is_document_uploaded === 0) {
          nextRoute = "/upload-documents";
        } else if (user.check_registration_status?.is_face_recognized === 0) {
          if (!localStorage.getItem("isMinor")) {
            nextRoute = "/face-recognition";
          } else {
            nextRoute = "/face-recognition-minor";
          }
        } else if (user.check_registration_status?.is_interviewing === 0) {
          nextRoute = "/interview-process";
        } else if (user.check_registration_status?.registration_status === 0) {
          // Set nextRoute if applicable
        } else if (user.check_registration_status?.is_verified === 0) {
          // Set nextRoute if applicable
          nextRoute = "/pending-approval";
        } else if (user.check_registration_status?.has_access_key) {
          nextRoute = "/enter-access-key";
        } else if (!two_factor) {
          nextRoute = "/qr-scan";
        } else {
          // If all conditions are met, user is fully logged in and ready for dashboard
          localStorage.setItem("isLoggedIn", false);
          dispatch(setIsLoggedIn(false));
        }

        // localStorage.setItem('isLoggedIn', true);
        // dispatch(setIsLoggedIn(true));

        // console.log("isLoggedIn:", localStorage.getItem('isLoggedIn'));
        // console.log(localStorage)
        localStorage.setItem(
          "messages_count",
          JSON.stringify({ theRegister: 0, devreg: 0, admin: 0 })
        );
        navigate(nextRoute);
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);

      if (error.response) {
        console.error("Error message:", error.response.data.message);
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleLogin = () => {
    loginUser();
  };

  const resendVerificationEmail = async () => {
    alert("verify");
    // try {
    //   const response = await axios.post(
    //     "https://devreg.co.nz/api/api/email/verification-notification",
    //     null,
    //     {
    //       headers: {
    //         "X-Requested-With": "XMLHttpRequest",
    //         Authorization: `Bearer ${storeToken}`,
    //       },
    //     }
    //   );

    //   setError(response.data.message);
    //   console.log("Resend email response:", response.data);
    // } catch (error) {
    //   if (
    //     error.response &&
    //     error.response.data &&
    //     error.response.data.message
    //   ) {
    //     setError(error.response.data.message);
    //   } else {
    //     setError("An error occurred while resending the verification email.");
    //   }
    //   console.error("Error resending email:", error);
    // }
  };

  return {
    fullName,
    setFullName,
    loginPass,
    setLoginPass,
    toggleEye,
    handleToggleEye,
    handleForgotPassword,
    handleSignUp,
    handleLogin,
    error,
    resendVerificationEmail,
    // mainImg,
    logoImg,
  };
};

export default useLogin;
